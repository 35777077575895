<template>
    <section v-if="detalleCotizaciones" class="op-ver-cotizacion">
        <tabla-general 
        alturaTabla="500px"
        :mostrarBuscador="false" 
        :usarPaginacion="false"
        :data="detalleCotizaciones">
            <el-table-column label="Ref" prop="ref" header-align="center" width="100" fixed>
                <template slot-scope="scope">
                    <p class="f-12 text-muted2 tres-puntos text-center">{{ scope.row.id }}</p>
                </template>
            </el-table-column>
            <el-table-column label="Nombre" prop="nombre" fixed>
                <template slot-scope="scope">
                    <p class="f-12 text-muted2 tres-puntos f-600">
                        {{ scope.row.nombre }}
                    </p>
                </template>
            </el-table-column>
            <el-table-column label="Tipo" prop="tipo" width="200" align="center" fixed>
                <template slot-scope="scope">
                    <p class="f-12 text-muted2">
                        {{ scope.row.tipo }}
                    </p>
                </template>
            </el-table-column>
            <el-table-column label="Cantidad" prop="cantidad" width="150" align="center" fixed>
                <template slot-scope="scope">
                    <p class="f-12 text-muted2">
                        {{ scope.row.total_unidades }}
                    </p>
                </template>
            </el-table-column>
            <el-table-column v-for="(item, index) in proveedores(detalleCotizaciones)" :key="index" width="150" align="center">
                <template slot="header" slot-scope="scope">
                    <el-tooltip  effect="light" placement="bottom" visible-arrow>
                        <div class="" slot="content">
                            <p class="f-12 text-center">{{ item.materiales_cotizados }}/100 materiales</p>
                            <p class="f-12 text-center">Fecha : {{ item.fecha_cotizacion | helper-fecha('DD/MM/YYYY') }}</p>
                            <p class="f-12 text-center">{{ item.referencia }}</p>
                        </div>
                        <p class="text-general f-600">{{ item.proveedor }}</p>
                    </el-tooltip>
                </template>
                <template slot-scope="scope">
                    <p class="f-12 text-muted2">
                        {{ getValueMaterial(scope.$index, item.id) }}
                    </p>
                </template>
            </el-table-column>
        </tabla-general>
        <div class="d-middle-center">
            <Pagination :pagination="pagination" layout="prev, pager, next" @paginate="paginateDetailCotizaciones" />
        </div>
    </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
    data(){
        return{
            
        }
    },
    async created () {
        await this.getSelects(['Action_get_select_monedas'])
        this.listarDetalleCotizacion();
    },
    computed: {
        ...mapGetters({
            detalleCotizaciones: 'oportunidades/ver/cotizaciones/detalleCotizaciones',
            select_monedas: 'selects/selects/select_monedas',
        }),
        pagination: {
            get() {
                return this.$store.getters['oportunidades/ver/cotizaciones/paginationDetail']
            },
            set(val){
                this.$store.commit('oportunidades/ver/cotizaciones/set_pagination_detail', val)
            }
        }
    },
    methods:{
        ...mapActions({
            Action_get_detalle_cotizaciones: 'oportunidades/ver/cotizaciones/Action_get_detalle_cotizaciones',
            getSelects: 'selects/selects/sync'
        }),
        abrirModalFiltros(){
            this.$refs.abrirModalFiltros.toggle()
        },
        listarDetalleCotizacion(){
            const params = {
                idOportunidad: this.$route.params.id,
                idCotizacion: this.$route.params.id_cotizacion,
            }
            this.Action_get_detalle_cotizaciones(params);
        },
        proveedores(detalle){
            if(!this.detalleCotizaciones) return
            const proveedoresUniques = {}
            detalle.forEach(el => {
                el.proveedores.forEach(({id, ...e}) => {
                    proveedoresUniques[id] = {
                        cantidadTotal: e.materiales_cotizados += proveedoresUniques[id]?.cantidadTotal ?? 0,
                        valorTotal: e.valor += parseInt(proveedoresUniques[id]?.valorTotal ?? 0),
                        ...e,
                        id
                    }
                })
            })
            return Object.values(proveedoresUniques);
        },
        getValueMaterial(indexMaterial, idProveedor){
            if (!this.detalleCotizaciones ) return ''
            const value = this.detalleCotizaciones[indexMaterial]?.proveedores.find(el => el.id == idProveedor)?.valor
            const moneda = this.detalleCotizaciones[indexMaterial]?.proveedores.find(el => el.id == idProveedor)?.id_moneda ?? 1
            if(value === undefined || moneda === undefined) return ''
            return `${this.select_monedas.find(el => el.id === moneda)?.sigla} $${value}`
        },
        paginateDetailCotizaciones(page = 1){
            this.pagination.current_page = page
            this.Action_get_detalle_cotizaciones(this.$route.params.id)
        },
    }
}
</script>

<style lang="scss" scoped>

</style>